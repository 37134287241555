import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import idsrvAuth from '@/idsrvAuth';

Vue.use(VueRouter)

const router = new VueRouter({
  // @todo do server configuration before using history mode
  // @see https://stackoverflow.com/a/51340156
  mode: 'history',

  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
      meta: {
        authName: idsrvAuth.authName
      }
    }
  ]
});

idsrvAuth.useRouter(router);

export default router
