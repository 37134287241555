<template>
  <div class="home">
    <HelloWorld msg="Hi!"/>
  </div>
</template>

<script>
import Vue from 'vue';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

export default Vue.extend({
  name: 'HomeView',
  components: {
    HelloWorld,
  },
});
</script>
