import Vue from 'vue';
import VueRouter from 'vue-router';
import { createPinia, PiniaVuePlugin } from 'pinia';
//import VueCompositionAPI from '@vue/composition-api';
import App from '@/App.vue';
import router from '@/router';
import idsrvAuth from '@/idsrvAuth';

// @todo use router in pinia
// @see https://stackoverflow.com/questions/70681667/cant-use-vue-router-and-pinia-inside-asingle-a-store
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(VueRouter);

//Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

idsrvAuth.startup().then(ok => {
  if (ok) {
    new Vue({
      pinia,
      router,
      render: h => h(App)
    }).$mount('#app');
  } else {
    console.log('Startup was not ok');
  }
});